.neighbourhood-map{
    min-height: calc(100vh - 145px);
    height: 100%;
    position: relative;
    & > div {
        position: absolute;
        height: 100%;
        width: 100%;
    }
}
.geolocation{
    &-button {
        margin-right: 10px;
        bottom: 200px !important;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px; 
        border-radius: 2px; 
        cursor: pointer; 
        background-color: rgb(255, 255, 255); 
        width: 40px; 
        padding:8px;
        & img {
            display: block;
            width: 24px;
            height: 24px;
        }
        &:focus {
            outline: none;
        }
    }
}
.center{
    &-button{
        background-color: #FFFFFF;
        border-radius: 8px;
        padding: 8px 24px;
        cursor: pointer;
        font-size: 14px;
        margin-bottom: 10px;
        color: var(--primary-color);
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: 'Montserrat';
    }
}
.error-message-box {
    animation: error-message-box-popup ease-out 300ms;
}

@keyframes error-message-box-popup {
    0% {
        bottom: -64px;
    }
    100% {
        bottom: 0;
    }
}
.neighbourhood-marker {
    background-color: var(--primary-color);
    color: var(--on-primary-color);
    border-radius: 40px;
    padding: 4px 20px;
    position: relative;
    display: block;
    &::before {
        display: block;
        left: 14px;
        top: 2px;
        height: 18px;
        width: 18px;
        line-height: 20px;
        position: absolute;
        content: '';
        z-index: 2;
        box-sizing: border-box;
    }
    &.--favorite {
        padding-left: 40px;
        &::before {
            background-image: url("./star.svg");
            background-repeat: no-repeat;
            background-size: 100%;
        }
    }
}
.gm-bundled-control{
    bottom: 180px !important;
}

