.neighbourhood__list {
    & .remove-following{
        height: 38px;
        background-color: #ffffff;
        &-img{
            display: inline-block;
            height: 20px;
            width: 20px;
            background: url(./follow.svg) no-repeat;
            background-size: 100%;
        }
        &:hover{
            & .remove-following-img{
                display: inline-block;
                height: 20px;
                width: 20px;
                background: url(./unfollow.svg) no-repeat;
                background-size: 100%;
            }
        }
    }
}