.info-card{
    display: inline;
    &__content{
        display: none;
    }
    & svg {
        display: inline;
        &:hover{
            & + .info-card__content{
                display: block;  
            }
        }
    }
  }