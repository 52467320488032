.mini-map {
  min-height: 280px;
  height: 100%;
  position: relative;
  & > div {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
