.sold-report {
  min-height: 200px;
  &__table {
    border-collapse: separate;
    border-spacing: 0 12px;

    &__odd-row {
      & td {
        background-color: var(--alpha-color);
        &:first-child {
          border-left-style: solid;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        &:last-child {
          border-right-style: solid;
          border-bottom-right-radius: 8px;
          border-top-right-radius: 8px;
        }
      }
    }
  }
  &__disable-select {
    user-select: none;
  }
  &__blur {
    filter: blur(5px);
    -webkit-filter: blur(5px);
  }
}
