/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* default body text */
  --default-color: #62727a;
  --on-default-color: #ffffff;

  /* icons and links */
  --primary-color: #417a9f;
  --on-primary-color: #ffffff;

  /* titles, field names, buttons and labels */
  --primary-soft-color: #b1cee2;
  --on-primary-soft-color: #000000;

  /* titles, field names, buttons and labels */
  --secondary-color: #28292e;
  --on-secondary-color: #ffffff;

  /* Highlights */
  --accent-color: #018363;
  --on-accent-color: #ffffff;

  /* negative / warnings */
  --warning-color: #cd3c3c;
  --on-warning-color: #ffffff;

  /* Alpha colors (input borders, tables and data containers)  */
  --primary-soft-color: #b1cee2;
  --on-primary-soft-color: #000000;

  --alpha-color: rgba(0, 0, 0, 0.04);
  --alpha-dark-color: rgba(0, 0, 0, 0.21);

  --accent-soft-color: #eafaf4;
  --on-accent-soft-color: #000000;

  /* Disabled */
  --disabled-color: #F4F6F6;
  --on-disabled-color: #999999;
}

/* Global */

html,
body {
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* app */
#root,
#rootModal {
  min-height: 100vh;
  width: 100vw;
  position: relative;
}
