.search-map {
  min-height: calc(100vh - 240px);
  height: 100%;
  position: relative;
  & > div {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    min-height: calc(100vh - 144px);
  }
}
.geolocation {
  &-button {
    margin-right: 10px;
    bottom: 200px !important;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    border-radius: 2px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    width: 40px;
    padding: 8px;
    & img {
      display: block;
      width: 24px;
      height: 24px;
    }
    &:focus {
      outline: none;
    }
  }
}
.center {
  &-button {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 8px 24px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 10px;
    color: var(--primary-color);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Montserrat';
  }
}
.error-message-box {
  animation: error-message-box-popup ease-out 300ms;
}

@keyframes error-message-box-popup {
  0% {
    bottom: -64px;
  }
  100% {
    bottom: 0;
  }
}
.gm-bundled-control {
  bottom: 180px !important;
}
