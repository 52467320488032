.follow-neighbourhood{
    & p.follow-check {
        & .following{
            display: block;
        }
        & .unfollow{
            display: none;
        }
        // &:hover{
        //     & .following{
        //         display: none;
        //     }
        //     & .unfollow{
        //         display: block;
        //     }
        // }
    }
    & button {
        &:hover{
            & + p.follow-check {
                & .following{
                    display: none;
                }
                & .unfollow{
                    display: block;
                }
            }
        }
    }
}
.card{
    display: none;
}
.follow-neighbourhood:hover > .card {
    display: block;
    background-color: #fff;
    position: absolute;
    border-radius: 8px;
    top: 50px;
    filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.6));
    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: 100%;
        left: 1.5em;
        border: .55rem solid transparent;
        border-top: none;
        border-bottom-color: #fff;
        filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
    }
  }


