.auth-layout {
    height: 100%;
    @media screen and (min-width: 1280px) {
        height: calc(100vh - 56px);
    }
    &_slideshow {
        overflow: hidden;
        max-width: 100%;
        &-slider {
            width: 100%;
            height: calc(100vh - 56px);
            white-space: nowrap;
            background-color: #fff;
            & .slide-image {
                display: inline-block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
