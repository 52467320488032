.neighbourhood-map_item{
    &-table{
        & tbody{
            & > tr:first-child , td:first-child{
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }
            & > tr:last-child , td:last-child{
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
    } 
}